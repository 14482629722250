<template>
    <v-dialog
        v-model="dialog"
        width="800"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
            >
                <v-icon small left>$add</v-icon> Add dataset
            </v-btn>
        </template>

        <v-card>
            <v-card-title class="grey lighten-2">
                Add new dataset
            </v-card-title>

            <v-card-text>
                <p>Upload new dataset.</p>
                <v-row>
                    <v-col cols=12>
                        <i-text-field label="Name"  v-model="datasetName" hide-details />
                    </v-col>
                    <v-col cols=12>
                        <client-selector v-model="datasetClient" />
                    </v-col>
                    <v-col cols=12>
                        <datatype-selector v-model="datasetDatatype" />
                    </v-col>
                    <v-col>
                        <v-form>
                            <upload-file @selected-files="onSelectedFilesChange" required />
                        </v-form>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="primary"
                    text
                    @click="uploadDataset"
                >
                    UPLOAD
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import bus from '@/ebus';
    import ClientSelector from '@/components/User/ClientSelector.vue';
    import UploadFile from '@/components/upload-file.vue';
    import DatasetAPI from './webapi';
    import DatatypeSelector from './DatatypeSelector.vue';

    export default {
        name: 'DataStoreCreateModal',

        components: {
            ClientSelector,
            DatatypeSelector,
            UploadFile,
        },

        data: () => ({
            dialog: false,
            datasetName: undefined,
            datasetClient: undefined,
            datasetDatatype: undefined,
            datasetFiles: [],
        }),

        watch: {
            show: {
                immediate: true,
                handler(newValue) {
                    this.dialog = newValue;
                },
            },
        },

        methods: {
            uploadDataset() {
                DatasetAPI.uploadDataset(
                    this.datasetName,
                    this.datasetClient,
                    this.datasetDatatype,
                    this.datasetFiles,
                ).then((response) => {
                    this.$helpers.notify('Uploading Dataset');
                    console.log(response);
                    this.$emit('new-dataset-created', response);
                    bus.$emit('new-dataset-created', response);
                    this.$helpers.notifySuccess('Dataset succesfuly Uploaded');
                }).catch((err) => {
                    this.$helpers.notifyError(`Dataset upload failed. Reason: ${err}`);
                }).finally(() => {
                    this.dialog = false;
                });
            },
            onSelectedFilesChange(files) {
                this.datasetFiles = files;
            },
        },
    };
</script>
